
<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/lessonschedule/groupteaching' }">团课列表</el-breadcrumb-item>
                <el-breadcrumb-item>团课详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div>
                <div style="height: 20px;"></div>
                <!--详情-->
                <el-form class="time-arrange-form" v-loading="loading" size="medium" :label-width="this.env.label_width">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="选择门店">
                                <span class="form-span-text">{{ info.store_name }}</span>
                            </el-form-item>
                            <el-form-item label="选择教室">
                                <span class="form-span-text">{{ info.classroom_name }}</span>
                            </el-form-item>
                            <el-form-item label="选择日期">
                                <span class="form-span-text">{{ info.date }}</span>
                            </el-form-item>
                            <el-form-item label="选择教练">
                                <span class="form-span-text">{{ info.coach_uuid === 'x' ? '无教练' : info.coach_user_nick
                                }}</span>
                            </el-form-item>
                            <el-form-item label="选择课程">
                                <span class="form-span-text">{{ info.title }}</span>
                            </el-form-item>
                            <el-form-item label="课程标题">
                                <span class="form-span-text">{{ info.lesson_name }}</span>
                            </el-form-item>
                            <el-form-item label="容纳人数">
                                <span class="form-span-text">{{ info.capacity }}人/{{ info.area }}平</span>
                            </el-form-item>
                            <el-form-item label="课程人数">
                                <span class="form-span-text">{{ info.lesson_quota }}人</span>
                            </el-form-item>
                            <el-form-item label="人数上限">
                                <span class="form-span-text">{{ info.quota }}人 </span>
                                <p v-if="info.bookings_sum">已预约:{{ info.bookings_sum }}人</p>
                            </el-form-item>
                            <el-form-item label="最少开课人数">
                                <span class="form-span-text">{{ info.bookings_min }}</span>
                            </el-form-item>
                            <el-form-item label="提前N小时确定">
                                <span class="form-span-text">开课前{{ info.determine_time }}小时确定是否达到最小开课人数。未达到则自动取消。0不设置</span>
                            </el-form-item>
                            <el-form-item v-if="returnSkuState()" label="规格信息">
                                <section class="specs__table">
                                    <div class="specs__table-header">
                                        <section class="specs__table-title">
                                            <p>规格标题</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>价格（元）</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>人数上限</p>
                                        </section>
                                    </div>
                                    <div class="specs__table-content">
                                        <section v-for="(item, index) in sku_data" :key="index">
                                            <div v-if="item.sku_name && item.sku_state === 1" class="specs__table-cell">
                                                <div class="specs__table-title">
                                                    <p>{{ item.sku_name }}</p>
                                                </div>
                                                <div class="specs__table-title">
                                                    <span class="form-span-text">{{ item.sku_price }}</span>
                                                </div>
                                                <div class="specs__table-title">
                                                    <span class="form-span-text">{{ item.sku_qty }}</span>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </section>
                            </el-form-item>
                            <el-form-item label="原价">
                                <span class="form-span-text">¥{{ info.originally_price }}</span>
                            </el-form-item>
                            <el-form-item label="售价">
                                <span v-if="returnSkuState()" class="form-span-text">¥{{ minPrice }}</span>
                                <span v-else class="form-span-text">¥{{ info.price }}</span>
                            </el-form-item>
                            <el-form-item label="开始时间">
                                <span class="form-span-text">{{ info.start_time }}</span>
                            </el-form-item>
                            <el-form-item label="课程时长">
                                <span class="form-span-text">{{ info.lesson_duration }}分钟</span>
                            </el-form-item>
                            <el-form-item label="结束时间">
                                <span class="form-span-text">{{ info.end_time }}</span>
                            </el-form-item>
                            <el-form-item label="排队等候">
                                <span class="form-span-text">{{ info.is_queue_up === 1 ? '允许等候' : '不允许等候' }}</span>
                            </el-form-item>
                            <el-form-item label="允许退款">
                                <span class="form-span-text">{{ info.is_refund === 1 ? '允许退款' : '不允许退款' }}</span>
                                <template v-if="info.is_refund === 1">
                                    <div class="form-tip">1.开课前{{ info.refund_time }}小时外取消，全额退款</div>
                                    <div class="form-tip">2.开课前{{ info.refund_time }}小时内仅取消订单不退款</div>
                                </template>
                            </el-form-item>
                            <el-form-item label="上架时间(选填)">
                                <span class="form-span-text">{{ info.publish_time }}</span>
                            </el-form-item>
                            <el-form-item label="提示(选填)">
                                <span class="form-span-text">{{ info.tip }}</span>
                            </el-form-item>
                            <el-form-item label="适用范围">
                                <span class="form-span-text">{{ info.range === 1 ? '全部用户' : '新用户专享' }}</span>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="isreturn()" size="medium" type="primary">返回
                                </el-button>
                            </el-form-item>


                        </el-col>
                    </el-row>
                </el-form>


            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '团课详情',
            loading: false,                 // 加载状态

            store_optionals: [],            // 可选门店信息
            classroom_optionals: [],        // 可选教室信息
            coach_optionals: [],            // 可选教练信息
            lesson_optionals: [],           // 可选课程信息
            classroom_occupy: [],            // 教室占用数据


            select_store_data: null,        // 选中的门店数据
            // 选中的教室数据
            select_classroom_data: {
                capacity: '',
                area: '',
            },
            // 规格信息
            sku_data: [],
            // 规格类型 0--> 无规格 1--> 多规格
            sku_type: 0,
            minPrice: 0,
            bookings: false,               // 是否有预约
            bookings_sum: 0,               // 预约人数
            good_uuid: '',                  // 课表uuid
            store_uuid: '',                 // 选择的门店id
            info: {},

            // 上架时间选择 配置
            publishTimePickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 获取详情
        getInfo(good_uuid = '') {
            let postdata = {
                api_name: "lessonschedule.group.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false;
                if (json.code === 0) {
                    this.bookings_sum = json.data.bookings_sum
                    this.bookings = json.data.bookings_sum > 0 ? true : false
                    this.store_uuid = json.data.store_uuid
                    this.info = json.data
                    if (json.data.sku_data.length > 0) {
                        this.sku_data = json.data.sku_data;
                        this.mathMin();
                    }
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.createdinfo()      // 创建info
            let good_uuid = this.$route.query.good_uuid
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getInfo(good_uuid)
            } else {
                this.loading = false
            }
        },
        // 创建info，清空info
        createdinfo() {
            this.info = {
                classroom_uuid: '',             // 选择的教室id
                week: '',
                date: '',
                title: '',
                quota: 0,
                bookings_min: 0,
                price: 0,
                originally_price: 0,
                determine_time: 2,
                start_time: '',
                end_time: '暂无',
                publish_time: '',
                is_queue_up: 1,
                is_refund: 1,
                tip: '',
                range: 1,
            }
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/lessonschedule/groupteaching'})
            this.$router.go(-1)
        },
        // 计算规格中的最小价格
        mathMin() {
            const { sku_data } = this;
            let data = [], priceData = [];
            sku_data.map(item => {
                if (item.sku_state !== 0) {
                    data = data.concat(item);
                }
            });
            data.map(item => {
                if (!this.Tool.is_empty(item.sku_price)) {
                    priceData = priceData.concat(parseFloat(item.sku_price));
                }
            })
            if (priceData.length > 0) {
                const price = Math.min(...priceData);
                this.minPrice = price;
            }
        },
        // 返回是否有设置规格
        returnSkuState() {
            const { sku_data } = this;
            let count = 0;
            if (sku_data.length === 0) {
                return false;
            } else {
                for (let i in sku_data) {
                    if (sku_data[i].sku_state === 0) {
                        count += 1;
                    }
                }
                if (count === sku_data.length) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    }
}
</script>
